import { message } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import { marked } from "marked";
import { getLocale } from "umi";
message.config({
  maxCount: 1,
});
export const calcCeatorData = (data: any) => {
  // console.log(data, '11111');

  const { update_dtime, create_users = {}, create_dtime, format_create_dtime, format_update_dtime } = data;

  const dataConfig = [
    {
      name: "创建人",
      value: create_users?.create_user ?? "",
    },
    {
      name: "最后更新人",
      value: create_users?.update_user ?? "",
    },
    {
      name: "创建时间",
      value: dayjs(create_dtime * 1000).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      name: "更新时间",
      value: typeof update_dtime == "number" ? dayjs(update_dtime * 1000).format("YYYY-MM-DD HH:mm:ss") : update_dtime,
    },
  ];
  return dataConfig;
};

export const basicInfoData = (data: any, tabulation: any) => {
  const mars: any[] = tabulation?.project?.vars?.mark ?? [];
  const name = mars.filter((item) => item.key === data.mark)?.at(0)?.name;
  const url = data?.request?.url;
  const mode = data?.request?.body?.mode;
  const method = data?.method;
  return [
    {
      key: "接口状态",
      value: name,
    },
    {
      key: "接口URL",
      value: url,
    },
    {
      key: "Content-Type",
      value: mode,
    },
    {
      key: "认证方式",
      value: method,
    },
  ];
};

export const getDataSource = (data, way, type) => {
  if (_.isArray(type)) {
    return type.reduce((mome, item) => {
      mome = { ...mome, item: data[item] };
      return mome;
    }, {});
  }
  return data[way] && data[way][type]?.parameter;
};

export const getClipboardText = (str) =>
  new Promise((resolve, reject) => {
    const clipboardObj = navigator.clipboard;
    const language = getLocale();
    const successTip = language === "zh-CN" ? "复制成功" : "Copy Successful";
    const failTip = language === "zh-CN" ? "复制失败" : "Copy Failed";
    if (clipboardObj) {
      navigator.clipboard
        .writeText(str)
        .then((data) => {
          message.success(successTip);
          resolve(data);
        })
        .catch(() => {
          try {
            const textarea = document.createElement("textarea");
            document.body.appendChild(textarea);
            // 隐藏此输入框
            textarea.style.position = "fixed";
            textarea.style.clip = "rect(0 0 0 0)";
            textarea.style.top = "10px";
            // 赋值
            textarea.value = str;
            // 选中
            textarea.select();
            // 复制
            document.execCommand("copy", true);
            // 移除输入框
            document.body.removeChild(textarea);
          } catch (error) {
            message.error(failTip);
          }
        });
    } else {
      try {
        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        // 隐藏此输入框
        textarea.style.position = "fixed";
        textarea.style.clip = "rect(0 0 0 0)";
        textarea.style.top = "10px";
        // 赋值
        textarea.value = str;
        // 选中
        textarea.select();
        // 复制
        document.execCommand("copy", true);
        // 移除输入框
        document.body.removeChild(textarea);
        if (str) {
          resolve(successTip);
          message.success(successTip, 0.8);
        }
      } catch (error) {
        message.error(failTip);
      }
    }
  });

interface APIentity {
  [keystring: string]: string;
}

export const replaceOperatorSymbol = (originString: string | undefined) => {
  if (!originString || typeof originString !== "string") {
    return "";
  }
  const result = originString?.replace(/&([^&;]+);/g, function (matchStr, b) {
    const entity: APIentity = {
      quot: '"',
      lt: "<",
      gt: ">",
      apos: "'",
      amp: "&",
      ldquo: "“",
      rdquo: "”",
    };
    const r = entity[b];
    return typeof r === "string" ? r : matchStr;
  });
  return result;
};

export const getResponseData = (list) => {
  const response = list.response;
  const obj = new Map();
  for (let key in response) {
    obj.set(key, response[key]);
  }
  const data = [...obj];
  return data;
};

// 转换数据结构
export const getProtosList = (protos) => {
  const obj = new Map();
  for (let key in protos) {
    obj.set(key, protos[key]);
  }
  const data = [...obj];
  return data;
};

type TYPEmockUrl = {
  url: string;
  parent_id?: string;
  mock_url?: string;
  target_id?: string;
};

// Mock url
export const getMockUrl = (values: TYPEmockUrl) => {
  const { url, parent_id, mock_url, target_id } = values;
  return `${url}/${parent_id}${mock_url}?apipost_id=${target_id?.toString()?.substr(0, 6)}`;
};

export const getHIdValue = (description: string) => {
  // const regex = /id="([^"]+)"/g;
  // let match;
  // const list = [];
  // while ((match = regex.exec(description)) !== null) {
  //     const idValue = match[1];
  //     list.push(idValue);
  // }
  // const ids = list.map((item) => {
  //     return {
  //         title: item,
  //         key: item,
  //         href: `#${item}`,
  //     };
  // });
  // return ids;
  const mdItems: any[] = [];
  function extractHeadings(content: any) {
    const regex = /<h[^>]*>(.*?)<\/h[^>]*>/g;
    const matches = content?.match(regex);
    return matches || [];
  }
  if (description) {
    const headering = extractHeadings(marked(description));

    headering?.forEach((it: string, index: number) => {
      const match = it?.match(/id=\"(.*?)\"/);
      const match1 = it?.match(/<h(\d+)/);
      // console.log(match);
      if (match && match1 && match?.[1]) {
        switch (match1?.[1]) {
          case "1":
            mdItems.push({
              key: `${match?.[1]}-${index}`,
              href: `#user-content-${match?.[1]}`,
              // target:`h${match1?.[1]}`,
              title: match?.[1] || " ",
            });
            break;
          case "2":
            mdItems.push({
              children: [
                {
                  key: `${match?.[1]}-${index}`,
                  href: `#user-content-${match?.[1]}`,
                  // target:`h${match1?.[1]}`,
                  title: match?.[1] || " ",
                },
              ],
            });
            break;
          case "3":
            mdItems.push({
              children: [
                {
                  children: [
                    {
                      key: `${match?.[1]}-${index}`,
                      href: `#user-content-${match?.[1]}`,
                      // target:`h${match1?.[1]}`,
                      title: match?.[1] || " ",
                    },
                  ],
                },
              ],
            });
            break;
          default:
            break;
        }
      }
    });
  }
  return mdItems;
};

export const changeHeadName = (name) => {
  if (name.length > 25) {
    return name.slice(0, 25);
  }
  return name;
};
