// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","redirect":"/preview","parentId":"@@/global-layout","id":"1"},"2":{"path":"docs/preview/:id/:salt","parentId":"@@/global-layout","id":"2"},"3":{"path":"/preview/:id/:salt","parentId":"@@/global-layout","id":"3"},"4":{"path":"/*","parentId":"@@/global-layout","id":"4"},"5":{"path":"/pageFail","parentId":"@@/global-layout","id":"5"},"6":{"path":"/passwordPage/:salt","parentId":"@@/global-layout","id":"6"},"7":{"path":"/docs/:issue_id","redirect":"/docs/detail/:issue_id?target_id=","parentId":"@@/global-layout","id":"7"},"8":{"path":"/docs/detail/:issue_id","parentId":"@@/global-layout","id":"8"},"9":{"path":"/docs/password/:issue_id","parentId":"@@/global-layout","id":"9"},"10":{"path":"/docs/defaultPage","parentId":"@@/global-layout","id":"10"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import( './EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__index" */'@/pages/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__index" */'@/pages/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__404__index" */'@/pages/404/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__PageFail__index" */'@/pages/PageFail/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__PasswordPage__index" */'@/pages/PasswordPage/index.tsx')),
'7': React.lazy(() => import( './EmptyRoute')),
'8': React.lazy(() => import(/* webpackChunkName: "p__Doc__components__Detail__index" */'@/pages/Doc/components/Detail/index.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__Doc__components__PasswordPage__index" */'@/pages/Doc/components/PasswordPage/index.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__Doc__components__DefaultPage__index" */'@/pages/Doc/components/DefaultPage/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/data/jenkins/workspace/beta-v8-docs/src/layouts/index.tsx')),
},
  };
}
